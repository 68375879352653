import React from "react";
import { MDBSideNav, MDBNavbarBrand, MDBIcon } from "mdbreact";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import navigationConfig from "./config";

import * as S from "./styled";

class SideNavigation extends React.Component {
  state = {
    navigation: {
      reports: {
        icon: "tachometer-alt",
        name: "Reports",
        isOpen: true,
      },
      affiliates: {
        icon: "home",
        name: "Affiliates",
        isOpen: true,
      },
      partners: {
        icon: "user",
        name: "Partners",
        isOpen: true,
      },
      users: {
        icon: "user",
        name: "Users",
        isOpen: true,
      },
      sites: {
        icon: "image",
        name: "Sites",
        isOpen: true,
      },
    },
  };

  renderLink(to, text) {
    const { pathname } = this.props.location;
    return (
      <S.Link
        onClick={this.props.onLinkClick}
        isactive={`${pathname === to}`}
        to={to}
      >
        {text}
      </S.Link>
    );
  }

  onFolderClick = (type) => {
    const { navigation } = this.state;
    navigation[type].isOpen = !navigation[type].isOpen;
    this.setState({
      navigation: { ...navigation },
    });
  };

  renderSiteFolder = (type, role) => {
    const { navigation } = this.state;
    const { pathname } = this.props.location;
    const isSelected =
      !navigation[type].isOpen &&
      navigationConfig[role][type].find((k) => k.url === pathname);
    return (
      <S.NavLink
        isSelected={isSelected}
        onClick={() => this.onFolderClick(type)}
      >
        <MDBIcon className="mr-2" icon={navigation[type].icon} />
        {navigation[type].name}
        <MDBIcon
          className={navigation[type].isOpen ? "rotate" : ""}
          icon="angle-down"
        />
      </S.NavLink>
    );
  };

  getUserRole = () => {
    const { user } = this.props;
    const isAdmin = user?.role?.includes("Admin");
    const isPartner = user?.role?.includes("Partner");
    const isAffiliate = user?.role?.includes("Affiliate");
    const isManager = user?.role?.includes("Manager");

    let role = "default";
    if (isAdmin) {
      role = "admin";
    }
    if (isPartner) {
      role = "partner";
    }
    if (isAffiliate) {
      role = "affiliate";
    }
    if (isManager) {
      role = "manager";
    }
    return role;
  };

  renderNavsDependRole = () => {
    const { user } = this.props;
    const role = this.getUserRole();
    const { navigation } = this.state;
    if (user?.id === "133" && !navigationConfig[role]['affiliates'].find(k => k.name === 'Balance')) { //julia@bk.com
      navigationConfig[role]['affiliates'].push({ url: '/balance', name: 'Balance'})
    }
    if (user?.id === "130" && !navigationConfig[role]['affiliates'].find(k => k.name === 'Balance')) { //alena@bk.com
      navigationConfig[role]['affiliates'].push({ url: '/balance', name: 'Balance'})
    }
    if (user?.id === '244') {
      navigationConfig['manager'] = {
        reports: [
          {
            name: 'Statistics',
            url: '/',
          },
        ]
      }
    }
    return Object.keys(navigationConfig[role]).map((folder) => {
      return (
        <div key={folder}>
          {this.renderSiteFolder(folder, role)}
          {navigation[folder].isOpen &&
            navigationConfig[role][folder].map((k) =>
             <React.Fragment key={`${k.url}-${k.name}`}>
              {this.renderLink(k.url, k.name)}
             </React.Fragment>
            )}
        </div>
      );
    });
  };

  render() {
    return (
      <S.Container className="white-skin">
        <MDBSideNav
          bg="https://mdbootstrap.com/img/Photos/Others/sidenav4.jpg"
          mask="strong"
          fixed
          breakWidth={this.props.breakWidth}
          triggerOpening={this.props.triggerOpening}
          style={{ transition: "padding-left .3s" }}
        >
          <MDBNavbarBrand
            style={{ paddingLeft: 20, color: "black" }}
            className="logo"
          >
            MediaMars
          </MDBNavbarBrand>
          {this.renderNavsDependRole()}
        </MDBSideNav>
      </S.Container>
    );
  }
}

const mapStateToProps = ({ app, auth }) => ({
  sidebarIsOpen: app.sidebarIsOpen,
  user: auth.user,
});

export default withRouter(connect(mapStateToProps, null)(SideNavigation));
