import React, { useEffect, useState } from 'react';

export const useContextMenu = () => {
  const [clicked, setClicked] = useState(false);
  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const handleClick = () => setClicked(false);
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);

    };
  }, [])

  const handleContainerClick = (event) => {
    event.preventDefault();
    setPoints({
      x: event.nativeEvent.layerX,
      y: event.nativeEvent.layerY,
    })
    setClicked(true);
  };

  return {
    clicked,
    setClicked,
    points,
    setPoints,
    handleContainerClick,
  }
}