/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { MDBCardBody, MDBCard, MDBSpinner, MDBBtn, MDBRow } from "mdbreact";
import { useLocation } from "react-router-dom";

import * as S from "./styled";
import { useSelector } from "react-redux";
import _ from "lodash";

import DateInput from "../../../../components/DateInput";

import moment from "moment";

import http from "../../../../services/http";

import { countRoi, countTotals, countAverageRoi } from "../../utils";

import useDateScrollHeader from "../../../../hooks/useDateScrollHeader";
import PartnerTable from "./PartnerTable";
import LinksTable from "./LinksTable";
import WebsTable from "./WebsTable";
import SubsTable from "./SubsTable";
import useLocalStorageState from "./useLocalStorageState";

const steps = {
  partner: "partner",
  link: "link",
  web: "web",
  sub: "sub",
};

const PartnerReport = () => {
  const { choosedSiteId } = useSelector((state) => state.app);

  const [loading, setLoading] = useState(false);

  const [info, setInfo] = useState([]);

  const [currentStep, setCurrentStep] = useState(steps.partner);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedLink, setSelectedLink] = useState(null);
  const [selectedLinkUid, setSelectedLinkUid] = useState(null);

  const [webData, setWebData] = useState([]);
  const [selectedWeb, setSelectedWeb] = useState(null);
  const [subData, setSubData] = useState([]);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  // const [form, setForm] = useState({
  //   From: moment().startOf("month").format("YYYY-MM-DD"),
  //   To: moment().format("YYYY-MM-DD"),
  // });

  const [form, setForm] = useLocalStorageState('roi-date', {
    From: moment().startOf("month").format("YYYY-MM-DD"),
    To: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    const step = queryParams.get('step');
    if (step === steps.link) {
      const linkId = queryParams.get('linkId');
      const linkUid = queryParams.get('linkUid');
      onSelectLink(linkId, linkUid);
    }
    if (step === steps.web) {
      const webId = queryParams.get('webId');
      const PartnerLinkId = queryParams.get('PartnerLinkId');
      setSelectedLink(PartnerLinkId);
      onSelectWeb(webId, PartnerLinkId);
    }
  }, []);

  useDateScrollHeader(
    {
      date: `${moment(form.From).format("DD-MM-YYYY")} - ${moment(
        form.To
      ).format("DD-MM-YYYY")}`,
    },
    [form]
  );

  const onLoad = async () => {
    setLoading(true);
    try {
      const { data } = await http.get("/reports/partner_roi", {
        params: {
          SiteId: choosedSiteId,
          ...form,
        },
      });

      const formattedData = data.map((item) => ({
        ...item,
        payoutWithoutCurrency: item.payout,
        roi: countRoi(
          item.turnover,
          item.investment,
          item.payoutCurrency,
          item.turnoverCurrency
        ),
      }));
      setInfo(formattedData);
    } catch (err) {}
    setLoading(false);
  };

  const onClickApply = async () => {
    if (currentStep === steps.partner || currentStep === steps.link) {
      onLoad();
    }
    if (currentStep === steps.web) {
      onSelectLink(selectedLink);
    }
    if (currentStep === steps.sub) {
      onSelectWeb(selectedWeb);
    }
  };

  useEffect(() => {
    onLoad();
    setCurrentStep(steps.partner);
  }, [choosedSiteId]);

  const grouped = _.groupBy(info, (i) => i.userEmail);

  const filteredByFillProfiles = info.filter(
    (i) => i.actionName === "fillprofile"
  );
  const filteredByPurchases = info.filter((i) => i.actionName === "purchase");

  const totalFillProfiles = _.sumBy(filteredByFillProfiles, (i) => i.count);
  const totalPurchases = _.sumBy(filteredByPurchases, (i) => i.count);

  const totals = countTotals(info, {
    investment: "payoutCurrency",
    turnover: "turnoverCurrency",
    coins: "number",
  });

  useEffect(() => {
    setInfo([]);
  }, [form]);

  const onSelectPartner = (partnerEmail) => {
    setCurrentStep(steps.link);
    setSelectedPartner(partnerEmail);
  };

  const onSelectLink = async (linkId, linkUid) => {
    setLoading(true);
    const { data } = await http.get("/reports/partner_roi_web", {
      params: {
        SiteId: choosedSiteId,
        PartnerLinkId: linkId,
        ...form,
      },
    });

    const formatted = data.map((i) => ({
      ...i,
      roi: countRoi(
        i.turnover,
        i.investment,
        i.payoutCurrency,
        i.turnoverCurrency
      ),
    }));
    setWebData(formatted);
    setSelectedLink(linkId);
    setSelectedLinkUid(linkUid)
    setCurrentStep(steps.web);
    setLoading(false);
  };

  const onSelectWeb = async (webId, link = selectedLink) => {
    setLoading(true);
    const { data } = await http.get("/reports/partner_roi_sub", {
      params: {
        SiteId: choosedSiteId,
        PartnerLinkId: link,
        WebId: webId,
        ...form,
      },
    });
    const formatted = data.map((i) => ({
      ...i,
      roi: countRoi(
        i.turnover,
        i.investment,
        i.payoutCurrency,
        i.turnoverCurrency
      ),
    }));
    setSubData(formatted);
    setSelectedWeb(webId);
    setCurrentStep(steps.sub);
    setLoading(false);
  };

  const onWebToggle = () => {
    setCurrentStep(steps.web);
  };

  const onLinkToggle = () => {
    setCurrentStep(steps.link);
  };

  const onPartnerToggle = () => {
    setCurrentStep(steps.partner);
  };
  const renderCurrentStep = () => {
    if (currentStep === steps.sub) {
      return (
        <>
          <S.StepLink onClick={onPartnerToggle}>Partner</S.StepLink> →{" "}
          <S.StepLink onClick={onLinkToggle}>Link</S.StepLink> →{" "}
          <S.StepLink onClick={onWebToggle}>Web</S.StepLink> →{" "}
          <S.StepLink>Sub</S.StepLink>
        </>
      );
    }
    if (currentStep === steps.web) {
      return (
        <>
          <S.StepLink onClick={onPartnerToggle}>Partner</S.StepLink> →{" "}
          <S.StepLink onClick={onLinkToggle}>Link</S.StepLink> →{" "}
          <S.StepLink>Web</S.StepLink>
        </>
      );
    }
    if (currentStep === steps.link) {
      return (
        <>
          <S.StepLink onClick={onPartnerToggle}>Partner</S.StepLink> →{" "}
          <S.StepLink>Link</S.StepLink>
        </>
      );
    }
    return (
      <>
        <S.StepLink>Partner</S.StepLink>
      </>
    );
  };
  return (
    <S.Container>
      <MDBCard>
        <MDBCardBody>
          <div>
            Total: FillProfiles - {totalFillProfiles}, Sales - {totalPurchases},
            Investment - {totals.investment} $, Coins - {totals.coins}, Turnover -{" "}
            {totals.turnover} $, ROI - {countAverageRoi(info)}
          </div>
        </MDBCardBody>
      </MDBCard>
      <div className="my-4">{renderCurrentStep()}</div>
      <MDBRow className="justify-content-between">
        <DateInput
          onChange={(val) =>
            setForm({
              To: val.to,
              From: val.from,
            })
          }
          value={{
            from: form.From,
            to: form.To,
          }}
        />
        <MDBBtn style={{ height: 50 }} onClick={onClickApply}>
          Apply
        </MDBBtn>
      </MDBRow>
      <MDBCard>
        {loading && <MDBSpinner />}
        {currentStep === steps.partner && (
          <PartnerTable data={grouped} onSelectPartner={onSelectPartner} />
        )}
        {currentStep === steps.link && (
          <LinksTable
            data={grouped[selectedPartner]}
            selectedPartner={selectedPartner}
            onSelectLink={onSelectLink}
          />
        )}
        {currentStep === steps.web && (
          <WebsTable
            selectedPartner={selectedPartner}
            selectedLink={selectedLink}
            selectedLinkUid={selectedLinkUid}
            data={webData}
            onSelectWeb={onSelectWeb}
          />
        )}
        {currentStep === steps.sub && (
          <SubsTable
            selectedWeb={selectedWeb}
            selectedLink={selectedLink}
            selectedLinkUid={selectedLinkUid}
            selectedPartner={selectedPartner}
            data={subData}
          />
        )}
      </MDBCard>
    </S.Container>
  );
};

export default PartnerReport;
