import {
  takeEvery,
  put,
  call,
} from 'redux-saga/effects';
import { getIdFromToken, getRoleFromToken, getEmailFromToken } from '../../helpers';
import http from '../../services/http';
import TokenStorage from '../../services/storage/token';
import RefreshTokenStorage from '../../services/storage/refreshToken';
import UserStorage from '../../services/storage/user';
import SiteStorage from '../../services/storage/site';

const SIGNIN_REQUEST = 'auth/SIGNIN_REQUEST';
const SIGNIN_SUCCESS = 'auth/SIGNIN_SUCCESS';
const SIGNIN_FAILURE = 'auth/SIGNIN_FAILURE';

const LOGOUT_REQUEST = 'auth/LOGOUT_REQUEST';
const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';

export const initialState = {
  authenticated: false,
  authError: 200,
  loading: false,
  user: {
    role: '',
    id: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SIGNIN_REQUEST:
      return {...state, authError: 200, loading: true};
    case SIGNIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        loading: false,
        user: action.user,
      };
    case SIGNIN_FAILURE:
      return {...state, authError: action.status, loading: false};

    case LOGOUT_REQUEST:
      return {...state};
    case LOGOUT_SUCCESS:
      return {...initialState, authenticated: false}

    default:
      return state;
  }
}

// <<<ACTIONS>>>
export const requestSignIn = payload => ({
  type: SIGNIN_REQUEST,
  payload,
});

export const requestLogOut = () => ({
  type: LOGOUT_REQUEST,
});

// <<<WORKERS>>>

function* signIn({payload}) {
  try {
    const {data} = yield call(http.post, '/auth/login', payload);
    yield call(UserStorage.save, {
      // email: payload?.email || '',
      role: getRoleFromToken(data.token),
      id: getIdFromToken(data.token),
      email: getEmailFromToken(data.token),
    });
    yield call(TokenStorage.save, data.token);
    yield call(RefreshTokenStorage.save, data.refreshToken);
    yield put({
      type: SIGNIN_SUCCESS,
      user: {
        role: getRoleFromToken(data.token),
        id: getIdFromToken(data.token),
        email: getEmailFromToken(data.token),
      },
    });
  } catch (error) {
    yield put({
      type: SIGNIN_FAILURE,
      status: error?.response?.status === 400 || error?.response?.status === 401 ? 400 : 500,
    });
  }
}

function* logOut() {
  yield call(TokenStorage.delete);
  yield call(UserStorage.delete);
  yield call(SiteStorage.delete);
  yield put({
    type: LOGOUT_SUCCESS,
  });
}

// <<<WATCHERS>>>
export function* watchSignIn() {
  yield takeEvery(SIGNIN_REQUEST, signIn);
}

export function* watchLogOut() {
  yield takeEvery(LOGOUT_REQUEST, logOut);
}
