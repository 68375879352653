import React from "react";

import { partnerHeaders } from "./constants";

import { countTotals, countAverageRoi } from "../../utils";

import BaseTable from "../BaseTable";
import { RoiCell } from "./styled";

const PartnerTable = ({ data = [], onSelectPartner }) => {
  const formattedData = Object.keys(data).map((partnerEmail) => {
    const partnerTotals = countTotals(data[partnerEmail], {
      investment: "payoutCurrency",
      turnover: "turnoverCurrency",
      coins: "number",
      ffd: "number",
    });
    return {
      partnerEmail: partnerEmail,
      investment: partnerTotals.investment,
      turnover: partnerTotals.turnover,
      ffd: partnerTotals.ffd,
      roi: countAverageRoi(data[partnerEmail]),
    };
  });

  const rowRender = (item) => (
    <tr>
      <td
        style={{ cursor: "pointer", fontWeight: 'bold', color: 'blue' }}
        onDoubleClick={() => onSelectPartner(item.partnerEmail)}
      >
        <u>
          <strong>{item.partnerEmail}</strong>
        </u>
      </td>
      <td>{parseInt(item.ffd)}</td>
      <td>{item.investment} $</td>
      <td>{item.turnover} $</td>
      <RoiCell roi={item.roi}>{item.roi}</RoiCell>
    </tr>
  );

  return (
    <BaseTable
      type="partner"
      data={formattedData}
      rowRender={rowRender}
      headers={partnerHeaders}
      rowKey="partnerEmail"
      initialSort={{
        field: "partnerEmail",
        direction: "asc",
      }}
    />
  );
};

export default PartnerTable;
