import styled, { css } from "styled-components";

export const Container = styled.div`
  .card-header {
    background-color: transparent;
  }
`;

export const StepLink = styled.span`
  ${({ onClick }) => {
    if (onClick) {
      return css`
        cursor: pointer;
        color: blue;
      `;
    }
  }};
`;

export const RoiCell = styled.td`
  background-color: ${({ roi }) => roi > 1.5 ? '#b2f2bb' : '#ffc9c9'};
  font-weight: bold;
  color: black;
`;

const typeColors = {
  'fillprofile': '#7c501a',
  'emailconfirmed': '#339900',
  'purchase': '#7289da',
  'firstpurchase': '#ffbf00',
}
export const TypeCell = styled.td`
  font-weight: bold;
  color: ${({ type }) => typeColors[type] || 'black'};
`;

export const Menu = styled.div`
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  position: absolute;
  background-color: white;
  z-index: 1111;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  color: black;
  &:hover {
    color: rgba(0, 0, 0, 0.4);
  }
`;
