import React from "react";

import { subsTableHeaders } from "./constants";

import BaseTable from "../BaseTable";
import { convertCurrencyToSymbol } from "../../utils";
import { RoiCell, TypeCell } from "./styled";

const SubsTable = ({
  data = [],
  selectedPartner,
  selectedLink,
  selectedLinkUid,
  selectedWeb,
}) => {
  const rowRender = (subItem) => (
    <tr style={{ backgroundColor: !subItem.isActive ? '#fadadd' : 'transparent' }}>
      <td style={{ fontWeight: 'bold' }}>
        <u>
          <strong>
            <div>{selectedPartner}</div>
            <div>{selectedLinkUid}</div>
            <div>{selectedWeb}</div>
            <div>{subItem.sub}</div>
          </strong>
        </u>
      </td>
      <TypeCell type={subItem.actionName}>{subItem.actionName}</TypeCell>
      <td>{parseInt(subItem.ffd)}</td>
      <td>{subItem.countAll}</td>
      <td>{subItem.count}</td>
      <td>{subItem.coins}</td>
      <td>
        {subItem.payout}{' '}
        {convertCurrencyToSymbol(subItem.payoutCurrency)}
      </td>
      <td>
        {subItem.investment}{' '}
        {convertCurrencyToSymbol(subItem.payoutCurrency)}
      </td>
      <td>
        {subItem.turnover}{' '}
        {convertCurrencyToSymbol(subItem.turnoverCurrency)}
      </td>
      <RoiCell roi={subItem.roi}>{subItem.roi}</RoiCell>
    </tr>
  );

  return (
    <BaseTable
      rowKey="sub"
      type="sub"
      data={data}
      rowRender={rowRender}
      headers={subsTableHeaders}
      initialSort={{
        field: "sub",
        direction: "asc",
      }}
    />
  );
};

export default SubsTable;
