import React, { useEffect, useState } from "react";

import { linksTableHeaders } from "./constants";

import BaseTable from "../BaseTable";
import { convertCurrencyToSymbol } from "../../utils";
import { Menu, RoiCell, TypeCell } from "./styled";
import { useContextMenu } from "./useContextMenu";

const LinksTable = ({ data = [], onSelectLink, selectedPartner }) => {
  const RowComp = ({ linkItem }) => {
    const { handleContainerClick, points, clicked } = useContextMenu();

    return (
      <tr style={{ backgroundColor: !linkItem.isActive ? '#fadadd' : 'transparent' }}>
      <td
        style={{ cursor: "pointer", fontWeight: 'bold', color: 'blue', position: 'relative' }}
        onDoubleClick={() => {
          if (!clicked) {
            onSelectLink(linkItem.linkId, linkItem.linkUid)
          }
        }}
        onContextMenu={handleContainerClick}
      >
       {clicked ? 
        <Menu top={points.y} left={points.x}>
          <div onClick={(e) => {
            e.preventDefault()
            window.open(`${window.location.origin}${window.location.pathname}?step=link&linkId=${linkItem.linkId}&linkUid=${linkItem.linkUid}`, '_blank')
          }}>Open in new window</div>
        </Menu> 
       : null}
        <u>
          <strong>
            <div>{selectedPartner}</div>
            <div>
              {linkItem.linkUid}({linkItem.linkName})
            </div>
          </strong>
        </u>
      </td>
      <TypeCell type={linkItem.actionName}>{linkItem.actionName}</TypeCell>
      <td>{parseInt(linkItem.ffd)}</td>
      <td>{linkItem.countAll}</td>
      <td>{linkItem.count}</td>
      <td>{linkItem.coins}</td>
      <td>{linkItem.dailyLimit}</td>
      <td>
        {linkItem.payout} {convertCurrencyToSymbol(linkItem.payoutCurrency)}
      </td>
      <td>
        {linkItem.investment} {convertCurrencyToSymbol(linkItem.payoutCurrency)}
      </td>
      <td>
        {linkItem.turnover} {convertCurrencyToSymbol(linkItem.turnoverCurrency)}
      </td>
      <RoiCell roi={linkItem.roi}>{linkItem.roi}</RoiCell>
    </tr>
    )
  }
  const rowRender = (linkItem) => {
    return <RowComp linkItem={linkItem} />
  };

  return (
    <BaseTable
      type="link"
      rowKey="linkUid"
      data={data}
      rowRender={rowRender}
      headers={linksTableHeaders}
      initialSort={{
        field: "linkUid",
        direction: "asc",
      }}
    />
  );
};

export default LinksTable;
