import React from "react";

import { websTableHeaders } from "./constants";

import BaseTable from "../BaseTable";
import { convertCurrencyToSymbol } from "../../utils";
import { Menu, RoiCell, TypeCell } from "./styled";
import { useContextMenu } from "./useContextMenu";

const WebsTable = ({
  data = [],
  onSelectWeb,
  selectedPartner,
  selectedLink,
  selectedLinkUid,
}) => {

  const RowComp = ({ webItem }) => {

    const { handleContainerClick, points, clicked } = useContextMenu();

    return (
      <>
        <tr style={{ backgroundColor: !webItem.isActive ? '#fadadd' : 'transparent' }}>
        <td
          style={{ cursor: "pointer", fontWeight: 'bold', color: 'blue' }}
          onDoubleClick={() => {
            if (!clicked) {
              onSelectWeb(webItem.web)
            }
          }}
          onContextMenu={handleContainerClick}
        >
        {clicked ? 
          <Menu top={points.y} left={points.x}>
            <div onClick={() => window.open(`${window.location.origin}${window.location.pathname}?step=web&webId=${webItem.web}&PartnerLinkId=${selectedLink}`, '_blank')}>Open in new window</div>
          </Menu> 
        : null}
          <u>
            <strong>
              <div>{selectedPartner}</div>
              <div>{selectedLinkUid}</div>
              <div>{webItem.web}</div>
            </strong>
          </u>
        </td>
        <TypeCell type={webItem.actionName}>{webItem.actionName}</TypeCell>
        <td>{parseInt(webItem.ffd)}</td>
        <td>{webItem.countAll}</td>
        <td>{webItem.count}</td>
        <td>{webItem.coins}</td>
        <td>{webItem.dailyLimit}</td>
        <td>
          {webItem.payout} {convertCurrencyToSymbol(webItem.payoutCurrency)}
        </td>
        <td>
          {webItem.investment} {convertCurrencyToSymbol(webItem.payoutCurrency)}
        </td>
        <td>
          {webItem.turnover} {convertCurrencyToSymbol(webItem.turnoverCurrency)}
        </td>
        <RoiCell roi={webItem.roi}>{webItem.roi}</RoiCell>
      </tr>
      </>
    )
  }
  const rowRender = (webItem) => <RowComp webItem={webItem} />

  return (
    <BaseTable
      rowKey="web"
      type="web"
      data={data}
      rowRender={rowRender}
      headers={websTableHeaders}
      initialSort={{
        field: "web",
        direction: "asc",
      }}
    />
  );
};

export default WebsTable;
