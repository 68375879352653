
export const groupParams = [
  {
    label: 'Day',
    id: 'day',
    group: 'day',
    main2: 'Day',
  },
  {
    label: 'Month',
    id: 'month',
    group: 'month',
    main2: 'Month',
  },
  {
    label: 'LinkId',
    id: 'linkid',
    group: 'vp',
    main2: 'LinkId',
  },
  {
    label: 'Web_Id',
    id: 'web_id',
    group: 'vp',
    main2: 'WebId',
  },
  {
    label: 'Sub_Id',
    id: 'sub_id',
    group: 'vp',
    main2: 'SubId',
  },
  {
    label: 'Utm_Source',
    id: 'utm_source',
    group: 'vp',
    main2: 'UtmSource',
  },
  {
    label: 'Utm_Campaign',
    id: 'utm_campaign',
    group: 'vp',
    main2: 'UtmCampaign',
  },
  {
    label: 'Utm_Term',
    id: 'utm_term',
    group: 'vp',
    main2: 'UtmTerm',
  },
  {
    label: 'Country',
    id: 'ipinfo_country',
    group: 'up',
    main2: 'Country',
  },
  {
    label: 'Age',
    id: 'age',
    group: 'up',
    main2: 'Age',
  },
  {
    label: 'User Role',
    id: 'userrole',
    group: 'up'
  },
  {
    label: 'OS',
    id: 'os',
    group: 'up',
    main2: 'os',
  },
  {
    label: 'Partner',
    id: 'partner',
    group: 'vp',
    main2: 'Partner',
  },
  {
    label: 'Landing',
    id: 'path',
    group: 'vp',
    main2: 'Path',
  },
  {
    label: 'Utm_Content',
    id: 'utm_content',
    group: 'vp',
    main2: 'UtmContent',
  },
]

export const graphOptionsTypes = [
  {
    id: 'fillProfiles',
    label: 'Fill Profiles',
  },
  {
    id: 'emailConfirmed',
    label: 'Confirmed',
  },
  {
    id: 'sales',
    label: 'Sales',
  },
  {
    id: 'salesQuantity',
    label: 'Coins',
  },
  {
    id: 'moneyAmount',
    label: 'Money',
  },
]

export const TABLE_HEADERS = [
  {
    header: 'FP➝SU',
    id: 'fpSu',
  },
  {
    header: 'FP➝4S',
    id: 'fp4s',
    siteIds: [37],
  },
  {
    header: 'FP➝FTD',
    id: 'fpFtd',
  },
  {
    header: 'Registrations',
    id: 'registrations',
  },
  {
    header: 'FillProfiles',
    id: 'fillProfiles'
  },
  {
    header: 'Confirmed',
    id: 'emailConfirmed',
  },
  {
    header: 'SignUps',
    id: 'signUps'
  },
  // {
  //   header: 'SignUp',
  //   id: 'signUps'
  // },
  {
    header: 'SMS4',
    id: 'sms4Plus35',
    siteIds: [37],
  },
  {
    header: 'Online (min/user) ',
    id: 'online',
  },
  {
    header: 'Sales',
    id: 'sales'
  },
  {
    header: 'FTD',
    id: 'ftd'
  },
  {
    header: 'Coins',
    id: 'coins'
  },
  {
    header: 'Money',
    id: 'money'
  },
]

export const selectOptions = [
  {
    label: 'Specialist',
    value: 'Specialist'
  },
  {
    label: 'Client',
    value: 'Client'
  }
]